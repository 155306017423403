import Box from '@mui/material/Box';
import FootballInfo from '../components/football/FootballInfo'

export default function Football(props){
  const {activeContent} = props

  if (activeContent !== 'Fussball'){
    return <></>;
  }
  return (
    <Box>
      <FootballInfo />
    </Box>
  );
}
