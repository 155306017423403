import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as FacebookLogo } from '../../images/facebook.svg'
import { ReactComponent as InstgrammLogo } from '../../images/instagram.svg'
import StoreIcon from '@mui/icons-material/Store';
import Button from '@mui/material/Button';


export default function TopBar(props){

  return (
    <Box sx={{height: "40px", width:"100%", backgroundColor: "secondary.main"}}>
      <Stack
        direction="row-reverse"
        spacing={4}
        sx={{marginRight: "16px"}}
      >
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="label"
          onClick={(e) => {
            e.view.window.open("https://www.facebook.com/fsg1921wisselsheim");
          }}
        >
          <FacebookLogo fill="#fff" width="15px" height="15px"/>
        </IconButton>
        <IconButton color="primary" aria-label="upload picture" component="label"
          onClick={(e) => {
            e.view.window.open("https://www.instagram.com/fsgwisselsheim");
          }}
        >
          <InstgrammLogo fill="#fff" width="15px" height="15px" />
        </IconButton>
        <Button
          key={'fan12'}
          target="_blank"
          href="https://fsg-wisselsheim.fan12.de/"
          rel="noopener"
          sx={{
            marginLeft: "12px",
            color: 'primary.main',
            backgroundColor: "secondary.main"
          }}
          startIcon={<StoreIcon />}
          variant="contained"
        >
          Fan Shop
        </Button>
      </Stack>
    </Box>
  )
}
