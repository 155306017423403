import Typography from '@mui/material/Typography';

export default function CompanyName(props){

  return (
    <>
      <Typography
        variant="h5"
        component="div"
        color="secondary.main"
        ml={2}
        sx={{ flexGrow: 1, fontWeight: '600', display: { xs: 'none', lg: 'block' } }}
      >
        Freie Sportgemeinschaft Wisselsheim e.V.
      </Typography>
      <Typography
        variant="h5"
        component="div"
        color="secondary.main"
        ml={2}
        sx={{ flexGrow: 1, fontWeight: '600', display: { xs: 'none', sm: 'block', lg: 'none' } }}
      >
        FSG Wisselsheim e.V.
      </Typography>
      <Typography
        variant="h6"
        component="div"
        color="secondary.main"
        ml={2}
        sx={{ flexGrow: 1, display: { xs: 'block', sm: 'none' } }}
      >
        FSG Wisselsheim e.V.
      </Typography>
    </>
  )
}
