

export const member = [
  {
    id: 'ceo',
    position: "Geschäftsführung / Spielausschuss",
    title: "Herr",
    firstName: "Lars",
    lastName: "Wolf",
    mobile: "+49 151 61134029",
    address: "Am Holzweg 4, 61231 Bad Nauheim",
    email: "Lars.Wolf@fsg-wisselsheim.de"
  },
  {
    id: 'finance',
    position: "Finanzen",
    title: "Herr",
    firstName: "Martin",
    lastName: "Scholtissek",
    mobile: "+49 160 93909622",
    address: "Steinfurther Str. 41, 35519 Oppershofen",
    email: "Martin.Scholtissek@fsg-wisselsheim.de"
  },
  {
    id: 'sports',
    position: "Sport",
    title: "Herr",
    firstName: "Mohamed",
    lastName: "Elassy",
    mobile: "+49 176 62186123",
    address: "Allensteinerstraße 1, 61169 Friedberg",
    email: "Mo.Elassy@fsg-wisselsheim.de"
  },
  {
    id: 'pr',
    position: "Öffentlichkeitsarbeit",
    title: "Herr",
    firstName: "Alexander",
    lastName: "Böer",
    mobile: "+49 1512 3683977",
    address: "Im Rosental 9a, 61231 Bad Nauheim",
    email: "Alexander.Boeer@fsg-wisselsheim.de"
  },
  {
    id: 'secretary',
    position: "Schriftführer / Wirt / Platzwart",
    title: "Herr",
    firstName: "Rainer",
    lastName: "Wolf",
    mobile: "+49 151 41233827",
    address: "Am Holzweg 4, 61231 Bad Nauheim",
    email: "Rainer.Wolf@fsg-wisselsheim.de"
  },
  {
    id: "president",
    position: "Präsident",
    title: "Herr",
    firstName: "Thomas",
    lastName: "Bogdoll",
    mobile: "+49 160 97897888",
    address: "",
    email: "Thomas.Bogdoll@fsg-wisselsheim.de"
  },
  {
    id: "gym",
    position: "Abt. Gymnastik",
    title: "Frau",
    firstName: "Lisa",
    lastName: "Diedrich",
    phone: "+49 6032 920014",
    mobile: "+49 151 23083276",
    address: "Lärchenweg 28, 61231 Bad Nauheim",
    email: "Lisa.Diedrich@fsg-wisselsheim.de"
  }
]
