import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MyCard from '../MyCard'


function GymSchedule(props) {
  return (
    <MyCard header="Trainingszeiten" headerType={"HeadlineContent"}>      
      <Box sx={{ width: '100%', textAlign: 'left' }}>
        <Typography variant="subtitle2">
          Dienstag 18.00 Uhr
        </Typography>
        <Typography variant="subtitle2">
           Wettertalhalle Rödgen
        </Typography>
      </Box>
    </MyCard>
  );
}
export default GymSchedule
