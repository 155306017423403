import React, {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import Body from './layout/Body'
import Club from './pages/Club'
import Gym from './pages/Gym'
import Home from './pages/Home'
import Impressum from './pages/Impressum'
import Partner from './pages/Partner'
import Disclaimer from './pages/Disclaimer'
import FindUs from './pages/FindUs'
import DataPrivacyPolicy from './pages/DataPrivacyPolicy'
import Contact from './pages/Contact'
import Football from './pages/Football'
import ScrollToTop from 'react-scroll-up'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import FooterContent from './components/footer/FooterContent'
import CookieConsent from "react-cookie-consent";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TopBar from './components/app/TopBar'
import CompanyName from './components/app/CompanyName'
import ToolbarMenue from './components/app/ToolbarMenue'
import ToolbarDrawer from './components/app/ToolbarDrawer'
import PartnerFooterTop from './components/app/PartnerFooterTop'
import { ReactComponent as FSGLogo } from './images/FSGLogo.svg'


interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}



function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export default function App(props: Props) {

  const [activeContent, setActiveContent] = useState('Home')
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  return (
    <>
    <CookieConsent
      location="bottom"
      buttonText="OK"
      cookieName="wwwFsg-WisselsheimDe"
      style={{ background: "#2B373B" }}
      buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      expires={150}
    >
      Diese Website benutzt Cookies. Wenn du die Website weiter nutzt, gehen wir von deinem Einverständnis aus.{" "}
      <span style={{ fontSize: "8px" }}>#teamblau-weiß</span>
    </CookieConsent>
    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <HideOnScroll {...props}>
      <AppBar component="nav">
        <TopBar />
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box mt={1}>
            <FSGLogo fill="primary.main" width="80px" height="80px"/>
          </Box>
          <CompanyName />
          <ToolbarMenue setActiveContent={setActiveContent} />
        </Toolbar>
      </AppBar>
      </HideOnScroll>
      <Box component="nav">
        <ToolbarDrawer setActiveContent={setActiveContent} handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} />
      </Box>
      <Box component="main" mt={8} sx={{ p: 3, minHeight: '79vh', width: '100%' }}>
        <Toolbar />
        <Body activeContent={activeContent}>
          <Home activeContent={activeContent} />
          <Club activeContent={activeContent} />
          <Gym activeContent={activeContent} />
          <Football activeContent={activeContent} />
          <Partner activeContent={activeContent} />
          <Impressum activeContent={activeContent} />
          <Disclaimer activeContent={activeContent} />
          <DataPrivacyPolicy activeContent={activeContent} />
          <Contact activeContent={activeContent} />
          <FindUs activeContent={activeContent} />
        </Body>
        <ScrollToTop
          showUnder={260}
          style={{
            position: 'fixed',
            bottom: 120,
            right: 20,
            color: 'secondary',
            cursor: 'pointer',
            transitionDuration: '0.2s',
            transitionTimingFunction: 'linear',
            transitionDelay: '0s'
          }}
        >
          <ArrowCircleUpIcon fontSize="large" />
        </ScrollToTop>
      </Box>
    </Box>
    <PartnerFooterTop activeContent={activeContent}/>
    <Box component="footer" sx={{backgroundColor: '#000', p: 3 }}>
      <FooterContent setActiveContent={setActiveContent} activeContent={activeContent} />
    </Box>
    </>
  );
}
