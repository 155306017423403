import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MyCard from '../MyCard'
import Stack from '@mui/material/Stack';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import EmailIcon from '@mui/icons-material/Email';

export default function FootballContact(props) {

  const {contactDetails, position} = props

  return (
    <MyCard header={`${contactDetails.title} ${contactDetails.firstName} ${contactDetails.lastName}`} subHeader={position} headerType={"HeadlineContent"}>
      <Box sx={{ width: '100%', textAlign: 'left' }}>
        <Stack direction="row" spacing={2}>
          <Box sx={{width: "20%"}}>
            <Typography variant="subtitle2">
              <PhoneAndroidIcon />
            </Typography>
            </Box>
            <Box>
            <Typography variant="subtitle2">
              {contactDetails.mobile}
            </Typography>
          </Box>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Box sx={{width: "20%"}}>
            <Typography variant="subtitle2">
              <EmailIcon />
            </Typography>
            </Box>
            <Box>
            <Typography variant="subtitle2" noWrap>
              {contactDetails.email}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </MyCard>
  );
}
