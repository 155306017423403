import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import {navItems} from './ToolbarMenueItemsConfig'
import Drawer from '@mui/material/Drawer';


export default function ToolbarDrawer(props){

  const { setActiveContent, handleDrawerToggle, mobileOpen, window } = props

  const drawerWidth = 240;

  const container = window !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        FSG
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.description} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemIcon>
                 {item.icon}
               </ListItemIcon>
              <ListItemText primary={item.description} onClick={() => setActiveContent(item.description)}/>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Drawer
      container={container}
      variant="temporary"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        display: { xs: 'block', md: 'none' },
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
      }}
    >
      {drawer}
    </Drawer>
  )
}
