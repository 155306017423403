import React, {useState, useEffect} from 'react';
import { InstagramEmbed } from 'react-social-media-embed';
import Grid from '@mui/material/Grid';
import MyCard from '../components/MyCard'
import 'pure-react-carousel/dist/react-carousel.es.css';
import {isEmpty} from 'lodash'

export default function FsgInstaStory(props) {
  const {activeContent} = props
  const [posts, setPosts] = useState([])

  useEffect(() => {
    const getInstaPosts = async () => {
      // const token = 'IGQVJYNzl5ME1JT2gtbEFOdEVkOEJTWDRhQXhZAaW9WbmN3N2xIdnNadThjbXcwZA01naE9sQnVaV1hQeV9Vc2wxakpNWGU2VDV5VDNhbkN6UUtLVWp6b3J1bU5aUlp2bFhmTXY2ZAHBrX1RzeFhLZA0pfegZDZD'
      const token = 'IGQWRQU1k5TFBNcW56eFlDaXhycWZASVEtrTmlyWURPVnBCNWtMMjVieGxPTk5wdm9EcndiQm5HcmhLY2E5REpZAcm9rc1RlbXlVM1hXZA0ZA4Nm9waC10bkNQeEVOdkZADNUdMYmIzVXgxWWJFSjlwODFBd212Y0lEbjBKR3owWU8xWTNZAM1kZD'
      const query = "id,username,timestamp,caption,media_url,media_type,permalink,children"
      const final_url = "https://graph.instagram.com/me/media?fields=" + query + "&access_token=" + token

      // try {
      //   let response = await fetch('https://www.fsg-wisselsheim.de/ig_api/');
      //   if (!response.ok){
      //     throw new Error(response.statusText);
      //   }
      //   const data = await response.json();
      //   console.log(data);
      // } catch (err) {
      //       // console.log(err)
      // }

      // const response = await fetch(final_url);
      // const data = await response.json();
      // setPosts(data.data)
      try {
          let response = await fetch(final_url);
          if (!response.ok){
            throw new Error(response.statusText);
          }
          const data = await response.json();
          setPosts(data.data)
      } catch (err) {
          // console.log(err)
      }

    };
    getInstaPosts();
    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

  if (activeContent !== 'Home'){
    return <></>;
  }
  console.log(posts);
  if (isEmpty(posts)){
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} sx={{justifyContent: 'center', display: "flex"}}>
          <MyCard>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              Broken InstagramEmbed :-(
            </div>
          </MyCard>
        </Grid>
      </Grid>
    );
  }
  return(
    <Grid container spacing={2}>
      {posts.slice(0, 3).map((post, index) => {
        return (
            <Grid item xs={12} md={4} sx={{justifyContent: 'center', display: "flex"}}>
              <MyCard>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <InstagramEmbed url={post.permalink} width={328} />
                </div>
            </MyCard>
          </Grid>

        )
      })}
    </Grid>
  );
}
