import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MyCard from '../MyCard'

function FootballSchedule(props) {
  return (
    <MyCard sx={{minHeight: "190px"}} header="Trainingszeiten" headerType={"HeadlineContent"}>      
      <Box sx={{ width: '100%', textAlign: 'left' }}>
      <Typography variant="subtitle2">
        Dienstag & Donnerstag 19.30 Uhr
      </Typography>
      <Typography variant="subtitle2">
        Sportplatz Wisselsheim
      </Typography>
      </Box>
    </MyCard>
  );
}
export default FootballSchedule
