import SingleCardEntity from '../SingleCardEntity'
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import HandshakeIcon from '@mui/icons-material/Handshake';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

export default function Downloads(){
  return(
    <div id="Downloads">
      <SingleCardEntity header="Downloads">
        <Stack direction={{xs: "column", md: "row"}}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <AutoStoriesIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Satzung" secondary={
              <Button
                 variant="link"
                 color="default"
                 startIcon={<PictureAsPdfIcon fontSize="small" />}
                 href="Satzung_FSG_01022013_Broschuere.pdf"
              >
                 Download
              </Button>
            } />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <AutoStoriesIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Geschäftsordnung" secondary={
              <Button
                 variant="link"
                 target="_blank"
                 color="default"
                 startIcon={<PictureAsPdfIcon fontSize="small" />}
                 href="Geschaeftsordnung.pdf"
              >
                 Download
              </Button>
            } />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <HandshakeIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Mitglied werden" secondary={
              <Button
                variant="link"
                target="_blank"
                color="default"
                startIcon={<PictureAsPdfIcon fontSize="small" />}
                href="Beitrittserklärung2016.pdf"
              >
                 Download
              </Button>
            } />
          </ListItem>
        </Stack>
      </SingleCardEntity>
    </div>
  )
}
