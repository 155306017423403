import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  GoogleReCaptchaProvider
} from 'react-google-recaptcha-v3';

const theme = createTheme({
  palette: {
    primary: {
      // main: '#1b263d'
      main: '#fff',
      secondary: '#1b263d'
    },
    secondary: {
     main: '#1b263d',     
   },
  }
});

ReactDOM.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider reCaptchaKey="6Le1vSEaAAAAAG_7rcuk88azOHK0fnuIjLc_AFWi">
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
reportWebVitals();
