import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import GymSchedule from './GymSchedule'
import GymContact from './GymContact'
import {ContentPlaceholder} from '../ContentPlaceholder'
import MyCard from '../MyCard'

export default function GymInfo(props) {

  return (
    <div id="GymInfo">
    <Grid container spacing={2}>
      <Grid item xs={12} sm={7} lg={9}>
        <MyCard sx={{textAlign: "left"}} header="Gymnastikabteilung">
          <Typography component="p">
            Die Gymnastikgruppe der Frauen besteht seit 1968 und erfreut sich seitdem eines guten Zuspruchs bei jungen und älteren Damen und ist demzufolge ständig gewachsen. Das Angebot ist durch die vorhandenen Geräte und sportlichen Möglichkeiten sehr vielseitig. Die eingesetzten Übungsleiterinnen sind fachlich und mental gut ausgebildet, so ist die aktuelle Übungsleiterin auf Osteoporosegymnstik spezialisiert.
            <ContentPlaceholder />
            Alle an Gymnastik interessierten Frauen sind herzlich Willkommen und können sich bei weiteren Fragen gerne an die Leitung der Gymnastikabteilung wenden − oder direkt die wöchentliche Trainingsstunde besuchen.
            <ContentPlaceholder />
            Natürlich freuen wir uns, wenn auch jüngere Frauen zu uns kommen, um langfristig den Fortbestand dieser zur Tradition gewordenen Frauengruppe zu sichern.
            <ContentPlaceholder />
            Gegenwärtig sind wir 25 Frauen, die sich wöchentlich in der Turnhalle der Wettertalschule in Rödgen treffen und sich für 1 Stunde unter fachlicher Anleitung rhythmisch und gymnastisch bewegen.
          </Typography>
        </MyCard>
      </Grid>
      <Grid item xs={12} sm={5} lg={3}>
        <GymSchedule />
        <Box mt={2}></Box>
        <GymContact />
      </Grid>
    </Grid>
    </div>
  );
}
