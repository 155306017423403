import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import HandshakeIcon from '@mui/icons-material/Handshake';
import HomeIcon from '@mui/icons-material/Home';
import SportsGymnasticsIcon from '@mui/icons-material/SportsGymnastics';
import Groups2Icon from '@mui/icons-material/Groups2';

export const navItems = [
  {
    description: 'Home',
    icon: <HomeIcon />
  },
  {
    description: 'Verein',
    icon: <Groups2Icon />
  },
  {
    description: 'Fussball',
    icon: <SportsSoccerIcon />
  },
  {
    description: 'Gymnastik',
    icon: <SportsGymnasticsIcon />
  },
  {
    description: 'Partner',
    icon: <HandshakeIcon />
  }
];
