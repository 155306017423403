import Typography from '@mui/material/Typography';
import SingleCardEntity from '../SingleCardEntity'
import {ContentPlaceholder} from '../ContentPlaceholder'

export default function History(props) {
  return (
    <div id="History">
      <SingleCardEntity header="Geschichte der FSG Wisselsheim" subHeader="Authoren: Thomas Bogdoll / Eberhard Bogdoll">
        <Typography component="p">
          Es war an einem Sommertag im Jahre 1921. Die damals 300 Einwohner zählende Gemeinde Wisselsheim, vor den Toren des Weltbades Bad Nauheim, war voll Sonnenschein. In der Hauptstrasse, im Haus Nummer 12 der Familie Gerber (heute steht das Haus nicht mehr, im Laufe der Zeit hat die Bäckerei Rosenfelder ihren Betrieb erweitert) beschlossen die Herren Josef Feuerstein, Heinrich Eichenauer und Wilhelm Gerber einen Sportverein zu gründen. Zu ihnen gesellte sich noch eine kleine Gruppe „Gleichgesinnter“ die da waren: Meinrad Gerber, Karl Gerber, Karl Kniß, Johannes Hopf, Herrmann May, Heinrich Lipp, Karl Roth, Konrad Roth, Philipp Rüfer, Friedrich Seng und Karl Seng. Das war die Geburtsstunde der Sportgemeinschaft 1921 Wisselsheim. In der darauffolgenden Gründungsversammlung im Gasthaus „Zum Löwen“ wurde Wilhelm Gerber in einer ersten offiziellen Vereinssitzung zum 1. Vorsitzenden gewählt.
          Der Verein wurde unter dem Namen „Arbeitersportverein – Blaue Elf – Wisselsheim“ im damaligen Arbeiter–, Turn– und Sportbund aufgenommen. Natürlich waren die ersten Schritte des jungen Vereins nicht einfach, aber schon bald erfolgreich. Zunächst musste ein Spielfeld gefunden werden! Und nachdem verschiedene Wiesen herhalten mussten, um überhaupt den Fussball nachjagen zu können bekam die „Blaue Elf“ eine Rasenfläche auf der Sode, in der Nähe der berühmten Salzwiesen zugewiesen. Man verzeichnete von Sonntag zu Sonntag (schon damals war dieser Tag der „Fussballtag“ in Wisselsheim) eine Aufwärtsentwicklung, welche schon 3 Jahre später zum respektvollen Meistertitel in der C-Liga führte. Ein Jahr später dann zum fast schon sensationellen Titel in der B-Liga.
          Der Jubel in Wisselsheim war rießengroß. Fortan waren die „Blauen“ in der damaligen A-Klasse immer in der Spitzengruppe zu finden.
          Im Jahre 1933 wurden alle Arbeitersportvereine in Deutschland aufgelöst, der Sportplatz auf der Sode den Verein abgenommen und als Weideland an einen Landwirt verpachtet. Noch im selben Jahr schuf die Gemeinde dem Verein einen neuen Sportplatz: Am Hitzberg.
        </Typography>
        <ContentPlaceholder />
        <Typography component="p">
          Mit Beginn des 2. Weltkrieges zerstörte dieser nicht nur manche Fußballmannschaft, sondern auch manches junge Fußball-Talent. Der Krieg brachte großes Unheil, aber er brachte es nicht fertig, trotz denkbar schlechten Voraussetzungen, den Fußballsport in Wisselsheim zum Stillstand zu bringen. 1946 wurde mit dem Neuaufbau des Vereins begonnen. Man gab dem Verein einen neuen Namen „Freie Sportgemeinschaft 1921 Wisselsheim“ und wählte Wilhelm Thönges zum 1. Vorsitzenden. Durch viele Heimatvertriebene aus dem Osten Deutschlands und auch durch Flüchtlinge aus den Großstädten war Wisselsheim damals auf ca. 620 Einwohner angewachsen. Die im idyllischen Wettertal gelegene Gemeinde hatte sich somit seit der Gründung des Vereins in 1921 mehr als verdoppelt. Der Hitzberg wurde fortan zur festen Sportstätte des Vereins. Kurze Zeit später wurde Karl Giger zum 1. Vorsitzenden der FSG 1921 Wisselsheim gewählt. Die folgende Nachkriegszeit war eine in jeder Hinsicht schwere Zeit. Zwei Zentner Kartoffel gegen 1 Paar Fußballschuhe waren ein schon fast übliches Tauschgeschäft. Mit Fußbällen war es nicht viel anders. Damals gab es noch die geschnürten Bälle, die auf Grund ihrer Beschaffenheit leicht zu Verletzungen führten. Manche Fußballblase musste bis zu 10 mal geflickt werden, bevor sie endgültig spieluntauglich wurde. Manche Fußball-Idealisten wurden somit schnell ihren Vorrat an Kartoffeln los. Trotzallem hatte Wisselsheim Dank der guten Vereinsarbeit wieder zwei schlagkräftige Seniorenmannschaften und sogar eine Jugendmannschaft auf die Beine gestellt.
        </Typography>
        <ContentPlaceholder />
        <Typography component="p">
          Nach der Währungsreform kam eine bessere Zeit. All die Sorgen der Nachkriegszeit waren vorbei. Fahrten auf Holzvergaser-Lastwagen oder auf Fahrrädern zu den Spielen, wo meist noch ein Sportkamerad auf dem Gepäckträger Platz nahm, zeigten neben einer großen Kameradschaft, das es sichtlich aufwärts ging. Die FSG spielte zunächst in der Kreisklasse B. Als dann nach einer Neueinteilung die Wisselsheimer in die C-Klasse eingeordnet wurden, zeigte man die guten Qualitäten und wurde noch im gleichen Spieljahr 1957/58 Meister der C-Klasse und von da an eine Spitzenmannschaft der Kreisklasse B.
          Karl Kniß, Hans Eichenauer, Herbert Rüfer (späterer Landrat des Wetteraukreises), Heinrich Rosenfelder und Helmut Gerber waren in den Jahren nach 1946 weitere Vereinsvorsitzende der FSG. Ab dem 06.Juli 1958 (mit einer kurzen Unterbrechung in den Anfangsjahren) bis zum 19.03 2004 war Eberhard Bogdoll, somit 46 Jahre und hessenweit einmalig, 1. Vorsitzender der FSG. Kein anderer prägte somit die Vereinsgeschichte der FSG Wisselsheim so deutlich wie er, nicht nur in Bezug auf die Dauer seiner Funktion, sondern auch im deutlichen Maße durch sein Engagement und rührigen Fleiß um das Wohle des Fußballvereins in Wisselsheim. Zahlreiche Vereinsausflüge wurden durch Eberhard Bogdoll angestoßen, von denen man sich heute noch erzählt wird (später mehr dazu). Sein Amt gab er, im Mai 2005 mit dem Bundesverdienstkreuz augezeichnet, anschliessend an seinen Sohn Stephan Bogdoll ab, welcher bis zum heutigen Tage dieses Amt, begleitet. Unter seiner Regie wurde im Sommer 2003 wieder eine Jugendfußballabteilung ins Leben gerufen.
          Am Spielbertrieb nimmt die FSG Wisselsheim seit dem 2. Weltkrieg ununterbrochen teil, sei es bei Verbands-, Pokal- oder Freundschaftsspielen. Auch mit großen Mannschaften des Fußballsports nahm man es auf. Freundschaftspiele gegen Ludwigsburg, den FSV Frankfurt, die Kickers aus Offenbach, eine Traditionsmannschaft des FC St. Pauli und zur Krönung die komplette Bundesligamannschaft des TSV 1860 München, anlässlich des 50jährigen Jubiläums, waren gern gesehene Gäste auf dem Wisselsheimer Hitzberg. Neben den sportlichen Wettkämpfen kam aber nie das gesellschaftliche Leben im Verein zu kurz. Heute ist fast jeder dritte Einwohner von Wisselsheim Mitglied des Vereins.
          Im Jahre 1968 wurde zudem noch eine Gymnastikabteilung gegründet, welche sich im Laufe der Jahre großer Beliebtheit erfreute und bis heute noch Bestand hat. Mit dem Nachbarverein SV Steinfurth bestand in den 80er Jahren eine Jugendspielgemeinschaft mit allen Mannschaften von der A bis zur F Jugend. Ein Schmuckstück ist die Sportanlage auf dem „Hitzberg“. Eine heute noch moderne Anlage mit einem Rasenplatz der viele auswärtige Vereine neidisch macht. Dazu gehört das schöne Vereinsheim, das zum größten Teil in Eigenhilfe der Vereinsmitglieder erstellt und 1968 seiner Bestimmung übergeben wurde. Im Weltmeisterjahr 1974 wurde auf Initiative der FSG eine Flutlichtanlage am Sportplatz errichtet, welche nach einigen Glühbirnenwechseln noch heute ihre Dienste verrichtet. 500 Zuschauer erlebten am 03.April 1974 die Flutlichtpremiere mit vielen prominenten Gästen. Zum Einweihungsspiel hatte die FSG eine Prominenten-Elf der deutschen Sportpresse eingeladen, welche von der FSG mit 2:1 besiegt wurde.
        </Typography>
        <ContentPlaceholder />
        <Typography component="p">
          In 1993 erfolgte die komplette Einfriedung des gesamten Sportgeländes und 3 Jahre später die Vergrößerung des Vereinsheims. Damit fand zu diesem Zeitpunkt eine Vollendung auf dem Sportpaltz am „Hitzberg“ statt, wie es sich die FSG Wisselsheim gewünscht hat. Zahlreiche Renovierungsarbeiten wurden bis heute durchgeführt und lassen das Gelände mit seinem „Häusi“ als Wisselsheimer Schmuckstück dastehen.
          Wisselsheim, das eine sehr alte Geschichte hat und im Jahre 805 erstmals urkundlich erwähnt wurde, konnte im August 1980 sein 1175jähriges Bestehen feiern. Zur Gestaltung der Festwoche hat die FSG einen großen Beitrag geleistet. Im Zuge der Gebietsreform schloß sich am 01. Februar 1971 Wisselsheim mit der Nachbargemeinde Rödgen zu einer neuen Einheitsgemeinde zusammen, die den Namen Wettertal trug. Schliesslich wurde Wisselsheim ab den 01. Januar 1972 zu Bad Nauheim eingegliedert und ist seitdem ein eigener Stadtteil des bekannten Weltbades.
          Im gleichen Jahr wurde auf Initiative der FSG Wisselsheim, hier durch den 1. Vorsitzenden Eberhard Bogdoll das Bad Nauheimer Stadtpokalturnier ins Leben gerufen und ist seitdem von der Bad Nauheimer Fußballbühne nicht mehr wegzudenken.
          Als eines der intensivsten Jahre kann das Jahr 1976 bezeichnet werden. Hier hat der Verein mit 60 Mitgliedern einen Ausflug nach Österreich unternommen, sowie eine mehrtägige Flugreise der Fußballer nach Prag, inklusive Freundschaftsspiel. Zudem wurde ein Sportfest abgehalten und im gleichen Jahr die Wisselsheimer Kirchweih durch eine Zeltkirmes vereinsseitig wieder in Schwung gebracht. Höhepunkt dieses Zeitraums war die Einweihung des neuen FSG Clubheims in der ehemaligen alten Schule des Ortes. In Zusammenarbeit mit der Stadt Bad Nauheim und vielen Fußballern der FSG wurde aus den Räumen, welche zwischenzeitlich als Schreinerei genutzt wurde, ein schmuckes Clubheim geschaffen. Nach der Erweiterung des „Hitzberg-Häusis“ wurden die Räumlichkeiten an eine Kirchengemeinde abgetreten.
          Viele Jahre hintereinander nahmen aktive und passive Vereinsmitglieder an den Ausflügen nach Österreich teil, welche für die meisten der eigentlich Jahresurlaub und die schönsten Tage des Jahres waren. Dazwischen lagen Fahrten der Fußballer u.a. nach Berlin, Paris, Kopenhagen, Salzburg, Wien und Prag, wo auch Freundschaftsspiele Bestandteil der Ausflüge waren. In guter Erinnerung blieben die Jubiläumsfeiern zum 40-, 50-, 60-, 70- und 75jährigen Vereinsbestehen. Für 2011 ist das 90jährige Jubiläumsfest geplant.
        </Typography>
        <ContentPlaceholder />
        <Typography component="p">
          Der große Dank gilt den vielen FSG-Idealisten, allen voran dem heutigen Ehrenvorsitzenden Eberhard Bogdoll, welche vieles entbehren mussten, um den Verein ihre Dienste zu erweisen. Immer wieder fanden sich treue Sportfreunde und Helfer und somit steht die FSG 1921 Wisselsheim heute fest im sportlichen und gesellschaftlichen Geschehen.
          <ContentPlaceholder />
          Gerne würden wir Sie mit dem „gesunden Hitzbergvirus“ anstecken und uns freuen, mit Ihnen die Tradition weiterzuleben.
        </Typography>
      </SingleCardEntity>
    </div>
  )
}
