import SingleCardEntity from '../components/SingleCardEntity'
import MyGoogleMap from '../components/GoogleMap/MyGoogleMap'

export default function FindUs(props){

  const {activeContent} = props

  if (activeContent !== 'FindUs'){
    return <></>;
  }

  return(
    <div id="FindUs">
      <SingleCardEntity header="Ihr Weg zu uns">
        <MyGoogleMap />
      </SingleCardEntity>
    </div>
  )
}
