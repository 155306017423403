import Grid from '@mui/material/Grid';
import MyCard from '../components/MyCard'
import SingleCardEntity from '../components/SingleCardEntity'

export default function Impressum(props){

  const {activeContent} = props

  if (activeContent !== 'Impressum'){
    return <></>;
  }



  return (
    <div id="Impressum">
      <SingleCardEntity header="Impressum">
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <MyCard header="Inhaber">
                Freie Sportgemeinschaft Wisselsheim e.V.<br />
                Am Holzweg 4<br />
                61231 Bad Nauheim<br />
              </MyCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <MyCard header="Bankverbindung" sx={{minHeight: "172px"}}>
                VB Mittelhessen<br />
                DE78513900000089315301<br />
                VBMHDE5F<br />
              </MyCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <MyCard header="Amtsgericht / Finanzamt" sx={{minHeight: "172px"}}>
                AG Friedberg VR 2797<br />
                Friedberg (Hessen)<br />
                Steuernummer 16 250 0434 0
              </MyCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <MyCard header="Rechtsform" sx={{minHeight: "172px"}}>
                Der Verein verfolgt ausschließlich und unmittelbar gemeinnützige Zwecke.
              </MyCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <MyCard header="Haftungshinweis">
                Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
              </MyCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <MyCard header="Layout und Konzept erstellt durch" sx={{minHeight: "149px"}}>
                Martin Scholtissek (Martin.Scholtissek@fsg-wisselsheim.de)
              </MyCard>
            </Grid>
        </Grid>
      </SingleCardEntity>
    </div>
  )
}
