import Box from '@mui/material/Box';
import GymInfo from '../components/gym/GymInfo'

export default function Gym(props){
  const {activeContent} = props

  if (activeContent !== 'Gymnastik'){
    return <></>;
  }
  return (
    <Box>
      <GymInfo />
    </Box>
  );
}
