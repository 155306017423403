import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MyCard from '../MyCard'
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import SingleCardEntity from '../SingleCardEntity'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import EmailIcon from '@mui/icons-material/Email';
import HouseIcon from '@mui/icons-material/House';
import { member } from './BoardMemberConfig'

const Details = ({headerIcon, content}) => {
  return(
    <Stack direction="row" spacing={2}>
      <Box sx={{width: "50px"}}>
        <Typography variant="subtitle2">
          {headerIcon}
        </Typography>
        </Box>
        <Box>
        <Typography variant="subtitle2">
          {content}
        </Typography>
      </Box>
    </Stack>
  )
}

const MemberDetails = ({data}) => {
  return (
    <Grid item xs={12} md={6} lg={4}>
      <MyCard header={`${data.firstName} ${data.lastName}`} headerType={"HeadlineContent"} subHeader={`${data.position}`} >
        <Box sx={{ width: '100%', textAlign: 'left' }}>
          <Details headerIcon={<PhoneAndroidIcon fontSize="small" />} header="Telefon" content={data.mobile} />
          <Details headerIcon={<EmailIcon fontSize="small" />} header="e-Mail" content={data.email} />
          <Details headerIcon={<HouseIcon fontSize="small" />} header="Anschrift" content={data.address} />
        </Box>
      </MyCard>

    </Grid>
  )
}


export default function BoardMember(){
  return(
    <div id="BoardMember">
      <SingleCardEntity header="Vorstand">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            {Object.keys(member).map((key, i) => (
              <MemberDetails data={member[key]} />
            ))}
          </Grid>
        </Box>
      </SingleCardEntity>
    </div>
  )
}


export const getBoardMemberDetails = (id) => {
    return member[member.findIndex(item => item.id === id)]
}
