import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

export const ClubSubMenue = () => {
  return (
    <Box sx={{display: "flex", justifyContent: "center"}}>
    <Stack direction={{xs: "column", md: "row"}}>
      <Link href="#BoardMember" variant="body2" mr={2} sx={{color: "secondary.main"}}>
        {'Vorstand'}
      </Link>
      <Link href="#Downloads" variant="body2" mr={2} sx={{color: "secondary.main"}}>
        {'Mitglied werden'}
      </Link>
      <Link href="#Downloads" variant="body2" mr={2} sx={{color: "secondary.main"}}>
        {'Satzung'}
      </Link>
      <Link href="#Downloads" variant="body2" mr={2} sx={{color: "secondary.main"}}>
        {'Geschäftsordnung'}
      </Link>
      <Link href="#History" variant="body2" mr={2} sx={{color: "secondary.main"}}>
        {'Geschichte'}
      </Link>
    </Stack>
    </Box>
  );
}
