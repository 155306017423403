import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {BernerImage, GigerImage, EssoImage, WagnerImage, CityImage, ImmoPowerImage, KvImage, AletterImage, AdditiveImage} from '../partner/PartnerImages'

export default function PartnerFooterTop(props){

  if (props.activeContent === 'Partner'){
    return <></>;
  }

  return(
    <Box sx={{ display: 'flex', justifyContent: 'center' }}  mt={1} mb={4}>
      <Stack
        direction={{xs: "inherit", md: "row"}}
        spacing={4}
        sx={{ flexWrap: 'wrap', gap: 1, width: "95%", justifyContent: "center" }}
      >
        <AdditiveImage width="200px"/>
        <BernerImage />
        <GigerImage />
        <EssoImage />
        <WagnerImage />
        <CityImage />
        <ImmoPowerImage />
        <KvImage />
        <AletterImage width="84px" height="134px"/>
      </Stack>
    </Box>
  )
}
