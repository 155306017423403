import { useState } from 'react';
import { send } from 'emailjs-com';
import SingleCardEntity from '../components/SingleCardEntity'
import MyCard from '../components/MyCard'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import EuroIcon from '@mui/icons-material/Euro';
import HandshakeIcon from '@mui/icons-material/Handshake';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// eslint-disable-next-line
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function Contact(props){

  const {activeContent} = props

  const [loadingState, setLoadingState] = useState(false)
  const [toSend, setToSend] = useState({
    your_name: '',
    your_subject: '',
    your_message: '',
    your_email: '',
  });

  if (activeContent !== 'Contact'){
    return <></>;
  }

  function validateEmail(email) {
    return emailRegex.test(email);
  }

  const resetForm = () => {
    setToSend({
      your_name: '',
      your_subject: '',
      your_message: '',
      your_email: '',
    })
  }

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!validateEmail(toSend.your_email)){
      setToSend({ ...toSend, 'your_email': 'ungültige Adresse' });
    } else {
      setLoadingState(true)
      send(
        'service_f478tvd',
        'template_2nxx77o',
        toSend,
        'alLXXyyvWowwKUQWg'
      )
      .then((response) => {
        if (response.status === 200){
          toast.success('Ihre E-Mail wurde erfolgreich versendet!', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoadingState(false)
          resetForm()
        } else {
          toast.error('Ihre E-Mail konnte nicht versendet werden!', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoadingState(false)
        }
        // console.log('SUCCESS!', response.status, response.text);
      })
      .catch((err) => {
        toast.error('Ihre E-Mail konnte nicht versendet werden!', {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoadingState(false)
        // console.log('FAILED...', err);
      });
    }
  };

  return (
    <div id="Contact">
      <SingleCardEntity header="Kontakt">
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <ContactForm onSubmit={onSubmit} toSend={toSend} handleChange={handleChange} loadingState={loadingState} resetForm={resetForm} />
            </Grid>
            <Grid item xs={12} md={6}>
              <MyCard header="E-Mail">
                Gern können Sie uns auch über die folgenden E-Mail Adressen kontaktieren.
                <ContactList />
              </MyCard>
            </Grid>
        </Grid>
      </SingleCardEntity>
    </div>
  )
}

const ContactList = () => {
  return (
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <EmojiPeopleIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Allgemeine Anfragen" secondary="info@fsg-wisselsheim.de" />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <EuroIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Fragen zu Rechnungen" secondary="finanzen@fsg-wisselsheim.de" />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <HandshakeIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Partner werden" secondary="sponsoring@fsg-wisselsheim.de" />
      </ListItem>
    </List>
  );
}

const ContactForm = ({onSubmit, toSend, handleChange, loadingState, resetForm}) => {
  return (
    <MyCard header="Kontaktformular">
      <Box component="form" onSubmit={onSubmit}>
      <Grid container direction={"column"} spacing={2}>
        <Grid item>
          <TextField
            fullWidth
            size="small"
            required
            id="your_name"
            name="your_name"
            value={toSend.your_name}
            label="Ihr Name (Pflichtfeld)"
            onChange={handleChange}
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            required
            size="small"
            id="your_email"
            name="your_email"
            value={toSend.your_email}
            label="Ihre E-Mail-Adresse (Pflichtfeld)"
            onChange={handleChange}
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            size="small"
            required
            id="your_subject"
            name="your_subject"
            value={toSend.your_subject}
            label="Betreff (Pflichtfeld)"
            onChange={handleChange}
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            multiline
            rows={4}
            required
            size="small"
            id="your_message"
            name="your_message"
            value={toSend.your_message}
            label="Ihre Nachricht (Pflichtfeld)"
            onChange={handleChange}
          />
        </Grid>
        <Grid item>
        <Stack direction="row" spacing={2} sx={{display: "flex", justifyContent: "right"}}>
          <Button
            variant="outlined"
            startIcon={<DeleteIcon />}
            onClick={() => resetForm()}
          >
            Leeren
          </Button>
          <LoadingButton
            loading={loadingState}
            loadingPosition="start"
            endIcon={<SendIcon />}
            variant="contained"
            type='submit'
          >
            Absenden
          </LoadingButton>

        </Stack>
        </Grid>
      </Grid>
      </Box>
    </MyCard>
  )
}
