import React from 'react'
import { GoogleMap, LoadScript, Marker, InfoBox  } from '@react-google-maps/api';
import logoMaps from '../../images/Logo40x39.png'

const containerStyle = {
  width: '100%',
  height: '400px'
};

const center = {
  lat: 50.37585245806115,
  lng: 8.76981396147674
};

const position = {
  lat: 50.37585245806115,
  lng: 8.76981396147674
}

function MyGoogleMap() {


  return (
    <LoadScript
      googleMapsApiKey="AIzaSyDDPbgfFJsPJXy6aAef43K4nxnHbKE21fM"
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={14}
      >
        { /* Child components, such as markers, info windows, etc. */ }
        <Marker
          position={position}
        />
        <InfoBox
          // onLoad={onLoad}
          options={{ closeBoxURL: '', enableEventPropagation: true }}
          position={position}
        >
          <div>
            <img  src={logoMaps}  alt="FSG Wisselsheim Logo"/>
            <div>
              Melbacher Weg, 61231, Bad Nauheim
            </div>
          </div>
        </InfoBox>
      </GoogleMap>
    </LoadScript>
  )
}

export default React.memo(MyGoogleMap)
