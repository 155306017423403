import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {ClubSubMenue} from '../components/club/ClubSubMenue'

const ShowSubMenue = ({activeContent}) => {
  let content = <></>
  switch (activeContent) {
    case "Verein":
      content = <ClubSubMenue />
      break;
    default:
      content = <></>;
  }
  return content;
}

export default function Body(props){
  const {activeContent} = props
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{textAlign: "center"}}>
          <ShowSubMenue activeContent={activeContent} />
        </Grid>
        <Grid item xs={12}>
          {props.children}
        </Grid>
      </Grid>
    </Box>
  )
}
