import Box from '@mui/material/Box';
import BoardMember from '../components/club/BoardMember'
import History from '../components/club/History'
import Downloads from '../components/club/Downloads'

export default function Club(props){
  const {activeContent} = props

  if (activeContent !== 'Verein'){
    return <></>;
  }
  return (
    <Box>
      <BoardMember />
      <Box mt={10}/>
      <Downloads />
      <Box mt={10}/>
      <History />
    </Box>
  );
}
