import {useEffect} from 'react'
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
// import fsgLogo from '../../images/Logo184x179.png'
import { ReactComponent as FSGLogo } from '../../images/FSGLogo.svg'

const MyLink = ({handleFooterLink, linkTxt, contentCategory}) => {
  return (
    <Link
      component="button"
      underline="none"
      sx={{marginBottom: "16px", color: '#fff'}}
      onClick={() => {
        handleFooterLink(contentCategory)
      }}
      >
      {linkTxt}
    </Link>
  )
}

export default function FooterContent(props){
  const { setActiveContent, activeContent } = props
  const handleFooterLink = (contentCategory) => {
    setActiveContent(contentCategory)
  }

  useEffect(() => {
    switch (activeContent) {
      case "Impressum":
      case "Disclaimer":
      case "DataPrivacyPolicy":
      case "Contact":
      case "FindUs":
          document.getElementById('root').scrollIntoView();
        break;
      default:

    }
  }, [activeContent])

  return (
    <Box>
    <Stack direction={{xs: "column", md: "row"}}>
      <Box sx={{ display: "flex", justifyContent: "center"}}>
        <FSGLogo fill="#fff"/>
      </Box>
      <Box ml={{xs: 0, md: 6}} mt={"auto"} mb={"auto"}>
        <Stack direction="column">
          <MyLink handleFooterLink={handleFooterLink} linkTxt="Impressum" contentCategory="Impressum" />
          <MyLink handleFooterLink={handleFooterLink} linkTxt="Haftungsausschluss" contentCategory="Disclaimer" />
          <MyLink handleFooterLink={handleFooterLink} linkTxt="Datenschutzerklaerung" contentCategory="DataPrivacyPolicy" />
        </Stack>
      </Box>
    <Box ml={{xs: 0, md: 6}} mt={"auto"} mb={"auto"}>
      <Stack direction="column">
        <MyLink handleFooterLink={handleFooterLink} linkTxt="Kontakt" contentCategory="Contact" />
        <MyLink handleFooterLink={handleFooterLink} linkTxt="Ihr Weg zu uns" contentCategory="FindUs" />
      </Stack>
    </Box>
    </Stack>
    </Box>
  )
}
