import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import HeadlineCard, {SubHeadlineCard, HeadlineContent} from '../components/Headlines'

const ShowSubHeader = ({subHeader}) => {
  if (subHeader === undefined){
    return <></>
  }

  return (
    <SubHeadlineCard>
      {subHeader}
    </SubHeadlineCard>
  );
}

const MyCardHeader = ({header, headerType}) => {
  if (headerType === "HeadlineContent"){
    return (
      <HeadlineContent>
        {header}
      </HeadlineContent>
    )
  }
  return (
    <HeadlineCard>
      {header}
    </HeadlineCard>
  )
}

const MyCard = ({header, subHeader, children, sx, headerType}) => {
  return (
    <Card sx={{...sx}} elevation={3}>
      {header !== undefined &&
        <CardHeader
          sx={{textAlign: "left"}}
          title={
            <MyCardHeader header={header} headerType={headerType} />
          }
          subheader={
            <ShowSubHeader subHeader={subHeader} />
          }
        />
      }
      <CardContent>
        {children}
      </CardContent>
    </Card>
  )
}

export default MyCard
