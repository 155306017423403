import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import {navItems} from './ToolbarMenueItemsConfig'

export default function ToolbarMenue(props){

  const {setActiveContent} = props
  const matches = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  return(
    <Box sx={{ display: { xs: 'none', md: 'flex' }, width: "50%", justifyContent: "end" }}>
      {matches &&
        navItems.map((item) => {
          return (
            <Button
              key={item.description}
              onClick={() => setActiveContent(item.description)}
              sx={{
                marginLeft: "12px",
                color: 'primary.main',
                backgroundColor: "secondary.main"
              }}
              startIcon={item.icon}
              variant="contained"
            >
              {item.description}
            </Button>
          )
        })
      }
      {!matches &&
        navItems.map((item) => {
          return (
            <IconButton
              color="secondary"
              aria-label={item.description}
              component="label"
              onClick={() => setActiveContent(item.description)}
            >
            {item.icon}
            </IconButton>

          )
        })
      }
    </Box>
  )
}
