import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import HeadlineCard, {SubHeadlineCard} from '../components/Headlines'

export default function SingleCardEntity(props){
  const {header, children, subHeader} = props

  const ShowSubHeader = ({subHeader}) => {
    if (subHeader === undefined){
      return <></>
    }

    return (
      <SubHeadlineCard>
        {subHeader}
      </SubHeadlineCard>
    );
  }

  return (
    <Grid item xs={12}>
      <Card elevation={3}>
        <CardHeader
          sx={{textAlign: "left"}}
          title={
            <HeadlineCard>
              {header}
            </HeadlineCard>
          }
          subheader={
            <ShowSubHeader subHeader={subHeader} />
          }
        />
        <CardContent>
          {children}
        </CardContent>
      </Card>
    </Grid>
  );
}
