import Box from '@mui/material/Box';
import SingleCardEntity from '../components/SingleCardEntity'
import MyCard from '../components/MyCard'
import Grid from '@mui/material/Grid';
import Masonry from '@mui/lab/Masonry';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import PersonIcon from '@mui/icons-material/Person';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import EmailIcon from '@mui/icons-material/Email';
import {getBoardMemberDetails} from '../components/club/BoardMember'

import {BernerImage, GigerImage, EssoImage, WagnerImage, CityImage, ImmoPowerImage, KvImage, AletterImage, AdditiveImage} from '../components/partner/PartnerImages'

export default function Partner(props){
  const {activeContent} = props

  const contactDetails = getBoardMemberDetails('pr')

  if (activeContent !== 'Partner'){
    return <></>;
  }

  const partners = [
    {image: <AdditiveImage width="100%" />},
    {image: <BernerImage />},
    {image: <GigerImage />},
    {image: <EssoImage width="100%" />},
    {image: <WagnerImage width="100%" />},
    {image: <AletterImage width="219px" height="337px" />},
    {image: <CityImage width="100%" />},
    {image: <ImmoPowerImage />},
    {image: <KvImage />},

  ];

  return (
    <div id="Partner">
      <SingleCardEntity header="Unsere Partner">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7} lg={9}>
            <Box sx={{ width: "100%", minHeight: 377 }}>
              <Masonry columns={{xs: 1,  lg: 2, xl: 3}} spacing={3}>
                {partners.map((partner, index) => (
                  <MyCard key={index} sx={{display: "flex", justifyContent: "center"}}>
                    {partner.image}
                  </MyCard>
                ))}
              </Masonry>
            </Box>
          </Grid>
          <Grid item xs={12} sm={5} lg={3}>
          <MyCard header="Partner werden" headerType="HeadlineContent">
            <Box sx={{ width: '100%', textAlign: 'left' }}>
              <Stack direction="row" spacing={2}>
                <Box sx={{width: "20%"}}>
                  <Typography variant="subtitle2">
                    <PersonIcon />
                  </Typography>
                  </Box>
                  <Box>
                  <Typography variant="subtitle2">
                    {`${contactDetails.title} ${contactDetails.firstName} ${contactDetails.lastName}`}
                  </Typography>
                </Box>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Box sx={{width: "20%"}}>
                  <Typography variant="subtitle2">
                    <PhoneAndroidIcon />
                  </Typography>
                  </Box>
                  <Box>
                  <Typography variant="subtitle2">
                    {contactDetails.mobile}
                  </Typography>
                </Box>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Box sx={{width: "20%"}}>
                  <Typography variant="subtitle2">
                    <EmailIcon />
                  </Typography>
                  </Box>
                  <Box>
                  <Typography variant="subtitle2" noWrap>
                    sponsoring@fsg-wisselsheim.de
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </MyCard>
          </Grid>
        </Grid>
      </SingleCardEntity>
    </div>
  );
}
