import Typography from '@mui/material/Typography';


const HeadlineCard = ({children}) => {
  return (
    <Typography variant="h5">
      {children}
    </Typography>
  );
}
export default HeadlineCard;

export const HeadlineContent = ({children}) => {
  return (
    <Typography variant="h6">
      {children}
    </Typography>
  );
}

export const SubHeadlineCard = ({children}) => {
  return (
    <Typography variant="caption">
      {children}
    </Typography>
  );
}
