import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FootballSchedule from './FootballSchedule'
import FootballContact from './FootballContact'
import MyCard from '../MyCard'
import {getBoardMemberDetails} from '../club/BoardMember'
import { useImageViewer } from 'react-image-viewer-hook'

export default function FootballInfo(props) {

  const { ImageViewer } = useImageViewer()

  return (
    <div id="FootballInfo">
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={6}>
        <MyCard sx={{textAlign: "left"}} header="Fussball">
          <Box sx={{width: "100%"}}>
            <img src="photos\mannschaft1.jpg" alt="Bild 1 FSG Mannschaft" width="100%" />
            <img src="photos\mannschaft2.jpg" alt="Bild 2 FSG Mannschaft" width="100%" />
            <ImageViewer />
          </Box>
        </MyCard>
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <FootballSchedule />
        <Box mt={2}></Box>
        <FootballContact
          contactDetails={getBoardMemberDetails('ceo')}
          position={'Spielausschuss'}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3}>
        <FootballContact
          contactDetails={{
            title: 'Herr',
            firstName: 'Stefan',
            lastName: 'Kugler',
            email: 'info@fsg-wisselsheim.de',
            mobile: '+49 176 64859656'
          }}
          position={'Trainer'}
        />
        <Box mt={2}></Box>
        <FootballContact
          contactDetails={getBoardMemberDetails('secretary')}
          position={'Platzwart'}
        />
      </Grid>
    </Grid>
    </div>
  );
}
